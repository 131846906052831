import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import EntryHeader from "../../components/SingleTemplateComponents/EntryHeader"
import ShareBar from "../../components/SingleTemplateComponents/ShareBar"
import ComponentParser from "../../utils/ComponentParser"
import VideoEmbed from "../../components/SingleTemplateComponents/VideoEmbed"
import Button from "../../components/Button"

const Event = ( { data: { event } } ) => {

	return (
		<Layout>
			<Seo
				title={event.title}
				canonical={event.seo?.canonical}
				uri={event.uri}
				socialImage={event.featuredImage?.node}
				description={event.seo?.metaDesc ? event.seo.metaDesc : event.excerpt.replace(/(<([^>]+)>)/gi, "") }
			/>

			{ event?.resourceMeta?.videoEmbed &&
				<div>
					<VideoEmbed 
						video={event.resourceMeta.videoEmbed}
					/>
				</div>
			}
            
			<EntryHeader
				title={event.title}
				date={event.eventMeta.eventDateDisplay + ' - ' + event.eventMeta.eventLocation}
				icon={ event?.organizations?.nodes[0]?.organizationMeta?.logo?.localFile }
				format="Event"
			/>

			<ShareBar 
				url={event.uri}
			/>

			<div className="max-w-2xl mx-auto mt-4 md:-mt-64 pb-16 px-4 md:px-0 min-h-[40vh]">
				{ event.eventMeta.eventLink &&
					<Button 
						link={event.eventMeta.eventLink}
						text="Visit Event Website"
						target="_blank"
					/>
				}
				{event.blocks &&
				event.blocks.map((block, i) => {
					return (
						<div key={i}>
							<ComponentParser
								block={block}
								page={event}
							/>
						</div>
					)
				})}
			</div>
				
		</Layout>
	);
}

export const eventQuery = graphql`
	query eventById(
		$id: String!
	) {
		event: wpEvent( id: { eq: $id } ) {
			... EventContent
		}
	}
`
export default Event